import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query PeoplePageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)people/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
      additionalGridCards {
        _id
        name
        position
        type
        text
        image {
            asset {
              url
              _id
            }
          }
        columns
        columns_m
      }
    }

    people: allSanityPerson(filter: {display: {ne: false}}, sort: {fields: [lastName], order: [ASC]}) {
      edges {
        node {
          id
          display
          profileColor {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          socialLinkedin
          name
          lastName
          position
          team
          department
          display
          slug {
            current
          }
        }
      }
    }
  }
`

const PeoplePage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  const personNodes = useMemo(() => {
    return data && data.people
      ? mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)
      : []
  }, [data.people])

  const gridPlugins = useMemo(() => {
    return data.page.additionalGridCards.map(plugin => ({
      ...plugin,
      position: plugin.position - 1
    }))
  }, [data.page.additionalGridCards])

  return (
    <Layout palette='red' page='team' hideContactBar>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

      {personNodes && personNodes.length > 0 && <PeopleGrid nodes={personNodes} body={page._rawBody} pageTitle={page.title} gridPlugins={gridPlugins} />}
    </Layout>
  )
}

export default PeoplePage
